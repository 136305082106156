import { graphql, useStaticQuery } from 'gatsby'
import { Landing, SocialLink } from '../types'

type QueryResponse = {
  contentfulAbout: {
    name: string
    description: string
    roles: string[]
    socialLinks: SocialLink[]
  }
  site: {
    siteMetadata: {
      deterministic: boolean
    }
  }
}

export const useSiteQuery = (): Landing & { deterministic: boolean } => {
  const { contentfulAbout, site } = useStaticQuery<QueryResponse>(graphql`
    query SiteQuery {
      contentfulAbout {
        name
        description
        roles
        socialLinks {
          url
          name
          icon: fontAwesomeIcon
        }
      }
      site {
        siteMetadata {
          deterministic
        }
      }
    }
  `)

  return { ...contentfulAbout, ...site.siteMetadata }
}
